@import "react-slideshow-image/dist/styles.css";


.indicatore{
    background-color:white;
    width:30px;
    height:30px;
    margin:20px;
    margin-bottom:50px;
    border-radius:100%;

}
.indicatore active{
    background-color: #000000!important;
}


@media screen and (max-width:800px){
    .indicatore{
        background-color:white;
        width:10px;
        height:10px;
        margin:5px;
        margin-bottom:20px;
        border-radius:100%;
    
    }
  }

