.cardLayout {
    display: flex;
    width: 300px;
    height: 425px;
    min-width: 211px;
    padding: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 16px;
    background: #EFEFEF;
}



.immagineCard {
    height: 251px;
    flex-shrink: 0;
    align-self: stretch;
    border-radius: 12px;
    background: lightgray 50% / cover no-repeat;
    margin-top: -20px;
    margin-bottom: 20px;
}

.testoNome {
    color: #000;
    text-align: center;
    font-family: DINPro;
    font-size: 20px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    margin: 0px;
}

.testoRuolo {
    color: #208B3A;
    font-family: DINPro;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0px
}

.gruppoIcone {
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.cardLayout.active {
    display: flex;
    width: 313px;
    height: 445px;
    min-width: 211px;
    padding: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 16px;
    border: 3px solid #2DC653;
    background: #EFEFEF;
}